import { useEffect } from 'react'
import { useDispatch } from './redux'
import { axiosInstance } from '../http/httpCore'
import { useContext, useRef } from 'react'
import _ from 'lodash'
import { initNotification } from 'src/app/services'
import { authAction } from 'src/app/store/reducers/auth.reducer'
import { settingAction } from 'src/app/store/reducers/setting.reducer'
import { studySetAction } from 'src/app/store/reducers/studySet.reducer'
import { iotdAction } from 'src/app/store/reducers/iotd.reducer'
import { mediaAction } from 'src/app/store/reducers/media.reducer'
import { appConfig } from 'src/app/config/appConfig'
import { chromeStorage } from 'src/extension/storageService'
import { NotificationContext, TConfigNofication } from 'src/app/context/notification.context'

const useErrorHandlerRequest = () => {
  const dispatch = useDispatch()
  const { openNotification } = useContext(NotificationContext) as TConfigNofication

  const errorInterceptor = axiosInstance.interceptors.response.use(
    (res) => res,
    (error) => {
      console.log(`error: `, error)
      const status = _.get(error, 'response.status')

      if (status === 401) {
        dispatch(authAction.logOut())
        dispatch(studySetAction.resetStudySet())
        dispatch(iotdAction.resetIotd())
        dispatch(settingAction.setNotification(initNotification))
        dispatch(mediaAction.reset())
        openNotification({ type: 'error', message: 'Token is expired!' })
        if (appConfig.appType === 'EXT') chromeStorage.clear()
      } else if (status === 500) {
        openNotification({ type: 'error', message: 'Something went wrong!' })
      } else {
        openNotification({ type: 'error', message: 'Something went wrong!' })
      }
    },
  )

  useEffect(() => {
    return () => {
      axiosInstance.interceptors.response.eject(errorInterceptor)
    }
  })
}

export default useErrorHandlerRequest
