import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'src/app/core/hooks/redux'
import { notification as noti, theme } from 'antd'
import styles from './style'
import { NotificationType } from 'src/app/models/app.model'
import { settingAction } from 'src/app/store/reducers/setting.reducer'

export const Notification: React.FC = () => {
  const { token } = theme.useToken()
  const classes = styles(token)

  const { notification } = useSelector((state) => state.setting)

  const dispatch = useDispatch()

  const [api, contextHolder] = noti.useNotification()

  const openNotificationWithIcon = (type: NotificationType = 'info') => {
    api[type]({
      message: notification.message,
      description: notification.description,
      onClose: () => {
        dispatch(settingAction.closeNotification())
      },
    })
  }

  useEffect(() => {
    if (notification.show) {
      console.log('notification: ', notification)
      openNotificationWithIcon(notification.type)
    }
  }, [notification.show])

  return <div className={classes.notification}>{contextHolder}</div>
}
