import { BrowserRouter } from 'react-router-dom'
import { RouterElement } from 'src/app/router'
import useErrorHandlerRequest from './core/hooks/axiosErrorHandleRequest'
import useHandleAuthRequest from './core/hooks/axiosHandleAuthRequest'
import { useDispatch, useSelector } from './core/hooks/redux'
import { useEffect } from 'react'
import { AppLayout } from './views/features/layout/layout'
import { actionAsyncApp } from './store/async/app.async'
import { ECategory, IPair } from './models/item.model'
import { IHttpResponse } from './models/http.model'
import { actionAsyncUser } from './store/async/user'
import { theme } from 'antd'

export const { useToken } = theme

export const App = () => {
  const { isAuth } = useSelector((state) => state.auth)

  const dispatch = useDispatch()

  useErrorHandlerRequest()
  useHandleAuthRequest()

  const getCats = async (cats: IPair<string, ECategory>[]) => {
    cats.map(async (cat) => await dispatch(actionAsyncApp.fetchIotd(cat.id)))
  }

  useEffect(() => {
    if (isAuth) {
      dispatch(actionAsyncUser.getUserInfo())
      dispatch(actionAsyncApp.fetchTypes())
      dispatch(actionAsyncApp.fetchCategories()).then((response) => {
        const payload = response.payload as IHttpResponse<IPair<string, ECategory>[]>
        if (payload && payload.isSuccess) getCats(payload.content)
      })
    }
  }, [isAuth])

  return (
    <>
      <BrowserRouter data-testid='browser-router-element'>
        <AppLayout>
          <RouterElement />
        </AppLayout>
      </BrowserRouter>
    </>
  )
}
