import { CloseCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { AutoComplete, Button, Checkbox, Col, Modal, Row, Space, Switch, theme } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import gStyles from 'src/style/appStyle'
import { exampleItem } from '.'
import styles from './style'
import { useAutoComplete } from 'src/app/helpers/hooks/autoComplete'
import { useState } from 'react'
import classNames from 'classnames'
import { exampleApi } from 'src/app/services/api/example.api'
import { IItem } from 'src/app/models/item.model'

interface IProps {
  nestIndex: number
}

export const ExampleItem: React.FC<IProps> = ({ nestIndex }) => {
  const { token } = theme.useToken()
  const classes = styles(token)
  const gClasses = gStyles(token)

  const [currentSearch, setCurrentSearch] = useState<string>('')
  const [auto, setAuto] = useState<boolean>(false)

  const [modal, modalRemoveExContextHolder] = Modal.useModal()

  const { options } = useAutoComplete(currentSearch, 'example')

  const { control, setValue, getValues, trigger } = useFormContext<IItem>()

  const { fields, remove, prepend, update } = useFieldArray({
    control,
    name: `meanings.${nestIndex}.examples`,
  })

  const onSearch = (searchText: string) => {
    setCurrentSearch(searchText)
  }

  const addExample = (): void => {
    prepend({ ...exampleItem })
  }

  const onSelect = (idxNested: number, idxExp: number, value: string) => {
    exampleApi.getExampleById(value).then(({ isSuccess, content }) => {
      if (isSuccess && content) {
        setCurrentSearch(content.original)
        setValue(`meanings.${idxNested}.examples.${idxExp}.original`, content.original)
        setValue(`meanings.${idxNested}.examples.${idxExp}.translation`, content.translation)
        setValue(`meanings.${idxNested}.examples.${idxExp}.id`, content.id)
      }
    })
  }

  return (
    <>
      <Space direction='vertical' size={[token.size, token.size]} className={gClasses.fulWidth}>
        <Col xs={24}>
          <Row gutter={[token.size / 2, token.size / 2]} align={'middle'}>
            <Col md={4} xs={8}>
              Example:
            </Col>

            <Col md={20} xs={16}>
              <Button onClick={addExample} type='primary' icon={<PlusOutlined />} />
            </Col>
          </Row>
        </Col>

        {fields.map((example, key) => {
          return (
            <Col md={24} key={example.id || key}>
              <Row gutter={[token.size / 2, token.size / 2]} align={'middle'}>
                <Col md={{ span: 20, offset: 4 }} xs={{ span: 24, offset: 0 }}>
                  <div className={classes.contentStyle}>
                    <Space
                      size={[token.size, token.size]}
                      direction='vertical'
                      className={gClasses.fulWidth}
                    >
                      <Row gutter={[token.size / 2, token.size / 2]} align={'middle'}>
                        <Col
                          md={4}
                          xs={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: token.size / 2,
                          }}
                        >
                          <Button
                            danger
                            onClick={() => {
                              modal.confirm({
                                type: 'warning',
                                title: 'Deleting...!',
                                content: 'Are you sure you want to delete this item?',
                                okText: 'Delete',
                                maskClosable: true,
                                closable: true,
                                onOk: () => {
                                  remove(key)
                                },
                              })
                            }}
                            icon={<DeleteOutlined />}
                          />

                          <Controller
                            control={control}
                            name={`meanings.${nestIndex}.examples.${key}.auto`}
                            render={({ field: { onChange, value, ref } }) => (
                              <Switch
                                ref={ref}
                                title={'Search an example with auto complete' + value + ''}
                                checked={value}
                                defaultChecked={value}
                                onChange={(e) => {
                                  onChange(e)
                                  trigger()
                                }}
                              />
                            )}
                          />
                        </Col>

                        <Col
                          md={20}
                          xs={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: token.size / 2,
                          }}
                        >
                          <AutoComplete
                            value={currentSearch}
                            disabled={!getValues(`meanings.${nestIndex}.examples.${key}.auto`)}
                            autoFocus={true}
                            allowClear={{
                              clearIcon: <CloseCircleOutlined style={{ fontSize: 14 }} />,
                            }}
                            options={options}
                            onSearch={onSearch}
                            onSelect={(value) => onSelect(nestIndex, key, value)}
                            placeholder='Search an example...'
                            className={classNames([gClasses.fulWidth])}
                          />
                        </Col>
                      </Row>

                      <Row gutter={[token.size / 4, token.size / 4]} align={'middle'}>
                        <Col md={4} xs={24}>
                          Original:
                        </Col>

                        <Col md={20} xs={24}>
                          <Controller
                            control={control}
                            name={`meanings.${nestIndex}.examples.${key}.original`}
                            render={({ field }) => (
                              <TextArea
                                disabled={getValues(`meanings.${nestIndex}.examples.${key}.auto`)}
                                autoSize={{ minRows: 2, maxRows: 4 }}
                                placeholder='Original'
                                {...field}
                              />
                            )}
                          />
                        </Col>
                      </Row>

                      <Row gutter={[token.size / 4, token.size / 4]} align={'middle'}>
                        <Col md={4} xs={24}>
                          Translation:
                        </Col>

                        <Col md={20} xs={24}>
                          <Controller
                            control={control}
                            name={`meanings.${nestIndex}.examples.${key}.translation`}
                            render={({ field }) => (
                              <TextArea
                                disabled={getValues(`meanings.${nestIndex}.examples.${key}.auto`)}
                                autoSize={{ minRows: 2, maxRows: 4 }}
                                placeholder='Translation'
                                {...field}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Space>
                  </div>
                </Col>
              </Row>
            </Col>
          )
        })}
      </Space>

      {modalRemoveExContextHolder}
    </>
  )
}
