import { useState } from 'react'
import { AutoComplete, Space, Tag, theme } from 'antd'
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons'
import styles from './style'
import classnames from 'classnames'
import { useAutoComplete } from 'src/app/helpers/hooks/autoComplete'
import { isGroupWord } from 'src/app/helpers/validate'
import { getArrayUniqueItem } from 'src/app/helpers/item'

interface IPros {
  onChange: (args: string[]) => void
  tags: string[]
  allowSpace?: boolean
}

export const InputTag: React.FC<IPros> = ({ tags, allowSpace = true, onChange }) => {
  const classes = styles(theme.useToken().token)

  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [editInputIndex, setEditInputIndex] = useState(-1)
  const [editInputValue, setEditInputValue] = useState('')
  const [currentSearch, setCurrentSearch] = useState<string>('')

  const { options } = useAutoComplete(currentSearch)

  const onSearch = (searchText: string) => {
    setCurrentSearch(searchText)
  }

  const handleClose = (removedTag: string) => {
    onChange([...tags.filter((tag) => tag !== removedTag)])
  }

  const onSelect = (tag: string) => {
    if (inputVisible) {
      allowSpace
        ? onChange(getArrayUniqueItem([...tags, tag]))
        : isGroupWord(tag)
          ? onChange([...tags])
          : onChange(getArrayUniqueItem([...tags, tag]))
      setInputVisible(false)
      setInputValue('')
    } else {
      if (allowSpace) {
        const newTags = [...tags]
        newTags[editInputIndex] = tag.trim()
        onChange(getArrayUniqueItem([...newTags]))
      } else {
        if (isGroupWord(tag)) {
          onChange([...tags])
        } else {
          const newTags = [...tags]
          newTags[editInputIndex] = tag.trim()
          onChange(getArrayUniqueItem([...newTags]))
        }
      }
      setEditInputIndex(-1)
      setInputValue('')
    }
  }

  const onInputChange = (data: string) => {
    inputVisible ? setInputValue(data) : setEditInputValue(data)
  }

  const onBlur = () => {
    setCurrentSearch('')
    if (inputVisible) {
      if (inputValue && tags.indexOf(inputValue) === -1) {
        allowSpace
          ? onChange([...tags, inputValue])
          : isGroupWord(inputValue)
            ? onChange([...tags])
            : onChange([...tags, inputValue])
      }
      setInputVisible(false)
      setInputValue('')
    } else {
      if (allowSpace) {
        const newTags = [...tags]
        newTags[editInputIndex] = editInputValue
        editInputValue ? newTags[editInputIndex] : newTags.splice(editInputIndex, 1)
        onChange([...newTags])
      } else {
        if (isGroupWord(editInputValue)) {
          onChange([...tags])
        } else {
          const newTags = [...tags]
          newTags[editInputIndex] = editInputValue
          editInputValue ? newTags[editInputIndex] : newTags.splice(editInputIndex, 1)
          onChange(getArrayUniqueItem([...newTags]))
        }
      }

      setEditInputIndex(-1)
      setEditInputValue('')
    }
  }

  return (
    <Space size={[0, 8]} wrap>
      {tags && tags.length > 0 && (
        <Space size={[0, 8]} wrap direction={'horizontal'}>
          {tags.map((tag, index) => {
            if (editInputIndex === index) {
              return (
                <AutoComplete
                  autoFocus={true}
                  key={tag + index}
                  value={editInputValue}
                  className={classnames([classes.tag, classes.inputAutoComplete])}
                  allowClear={{ clearIcon: <CloseCircleOutlined style={{ fontSize: 14 }} /> }}
                  options={options}
                  onSearch={onSearch}
                  onSelect={onSelect}
                  onChange={onInputChange}
                  onBlur={onBlur}
                  placeholder={editInputValue}
                />
              )
            }

            return (
              <Tag
                key={tag + index}
                closable={true}
                className={classnames([classes.tag])}
                onClose={() => handleClose(tag)}
              >
                <span
                  onDoubleClick={(e) => {
                    setEditInputIndex(index)
                    setEditInputValue(tag)
                    e.preventDefault()
                  }}
                >
                  {tag}
                </span>
              </Tag>
            )
          })}
        </Space>
      )}

      {inputVisible ? (
        <AutoComplete
          value={inputValue}
          autoFocus={true}
          className={classnames([classes.tag, classes.inputAutoComplete])}
          options={options}
          onSearch={onSearch}
          onSelect={onSelect}
          onChange={onInputChange}
          onBlur={onBlur}
          placeholder='New word'
          allowClear={{ clearIcon: <CloseCircleOutlined style={{ fontSize: 14 }} /> }}
        />
      ) : (
        <Tag
          className={classnames([classes.tag, classes.tagPlus])}
          onClick={() => setInputVisible(true)}
        >
          Add <PlusOutlined />
        </Tag>
      )}
    </Space>
  )
}
