/* Popup page */
import { ILoginResponse } from 'src/app/models/user.model'
import { LoginForm } from 'src/app/views/features/loginForm/loginForm'
import { chromeStorage } from './storageService'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ECategory, EType, IExample, IItem, IPair } from 'src/app/models/item.model'
import { initItem } from 'src/app/views/features/modals/itemModal'
import { theme } from 'antd'
import styles from './style'
import gStyles from 'src/style/appStyle'
import useErrorHandlerRequest from 'src/app/core/hooks/axiosErrorHandleRequest'
import useHandleAuthRequest from 'src/app/core/hooks/axiosHandleAuthRequest'
import { appApi } from 'src/app/services/api/app.api'
import { IHttpResponse } from 'src/app/models/http.model'
import { HeaderExt } from 'src/app/views/features/headerExt/header'
import { EPageExt } from 'src/app/models/app.model'
import { RegisterForm } from 'src/app/views/pages/registerForm/registerForm'
import { CRUFormExt } from 'src/app/views/features/modals/itemModal/ItemFormExt'
import { FormSearchItem } from 'src/app/views/features/formSearchItem/formSearchItem'
import { ExampleFormAdd } from 'src/app/views/features/exampleOverview/exampleFormAdd'
import { useSelector } from 'src/app/core/hooks/redux'
import { Loading } from 'src/app/views/features/loading/loading'

export const PopupExt = () => {
  const { token } = theme.useToken()
  const classes = styles(token)
  const gClasses = gStyles(token)

  const [isLogin, setIsLogin] = useState<boolean>(true)
  const [cats, setCats] = useState<IPair<string, ECategory>[]>([])
  const [types, setTypes] = useState<IPair<string, EType>[]>([])
  const [currentPage, setCurrentPage] = useState<EPageExt>(EPageExt.ADD)

  const { isShowSearchFormItem } = useSelector((state) => state.setting)

  const methods = useForm<IItem>({ defaultValues: initItem })

  useErrorHandlerRequest()
  useHandleAuthRequest()

  const onLogin = async ({ isSuccess, content }: IHttpResponse<ILoginResponse>) => {
    if (isSuccess) {
      // save auth info
      await chromeStorage.set(content)
      // set isAuth to storage
      await chromeStorage.set({ isAuth: true })
      setIsLogin(true)
      getStaticData()
      setCurrentPage(EPageExt.ADD)
    }
  }

  const getStaticData = async () => {
    let cats = (await chromeStorage.get(['cats'])).cats as IPair<string, ECategory>[]
    let types = (await chromeStorage.get(['types'])).types as IPair<string, EType>[]

    if (!cats) cats = (await appApi.getCategories()).content
    if (!types) types = (await appApi.getTypes()).content

    console.log(`cats: `, cats)
    console.log(`types: `, types)

    setCats(cats.map((cat) => ({ ...cat, value: cat.id })))
    setTypes(types.map((type) => ({ ...type, value: type.id })))
  }

  const onPageChange = async (page: EPageExt) => {
    setCurrentPage(+page)
    if (page === EPageExt.LOGIN || page === EPageExt.REGISTER) {
      await chromeStorage.set({ isAuth: false })
      setIsLogin(false)
    }
  }

  const onSucces = (data: IExample) => {
    console.log(`data: `, data)
  }

  useEffect(() => {
    const checklogin = async () => {
      const loggedIn = (await chromeStorage.get(['isAuth'])).isAuth
      setIsLogin(loggedIn)
      await getStaticData()
    }

    checklogin()
  }, [])

  return (
    <div className={classes.ext}>
      <HeaderExt isAuth={isLogin} onPageChange={onPageChange} />

      {!isLogin && (
        <>
          {currentPage === EPageExt.LOGIN && (
            <div className={classes.loginForm}>
              <LoginForm onLogin={onLogin} showBanner={false} />
            </div>
          )}

          {currentPage === EPageExt.REGISTER && <RegisterForm showBanner={false} />}
        </>
      )}

      {isLogin && (
        <>
          {currentPage === EPageExt.ADD && (
            <div className={classes.cruForm}>
              <FormProvider {...methods}>
                <CRUFormExt categories={cats} types={types} />
              </FormProvider>
            </div>
          )}

          {currentPage === EPageExt.ADDEX && (
            <div style={{ padding: (token.size / 4) * 3 }}>
              <h2 style={{ fontWeight: 'normal' }}>Add a Example</h2>
              <ExampleFormAdd mode={'light'} onSucces={onSucces} />
            </div>
          )}

          {/* {currentPage === EPageExt.SEARCH && <FormSearchItem filter={false} submit={false} />} */}
        </>
      )}

      <Loading />
    </div>
  )
}
