import { MenuOutlined, DownOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Layout, MenuProps, Row, Space, theme } from 'antd'
import classNames from 'classnames'
import { items, addNewType } from './menus'
import styles from './style'
import gStyles from 'src/style/appStyle'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { MainMenu } from '../mainMenu/mainMenu'
import { useDispatch, useSelector } from 'src/app/core/hooks/redux'
import { studySetAction } from 'src/app/store/reducers/studySet.reducer'
import { authAction } from 'src/app/store/reducers/auth.reducer'
import { iotdAction } from 'src/app/store/reducers/iotd.reducer'
import { settingAction } from 'src/app/store/reducers/setting.reducer'
import { configAction } from 'src/app/store/reducers/config.reducer'
import { mediaAction } from 'src/app/store/reducers/media.reducer'
import { itemAction } from 'src/app/store/reducers/items.reducer'
import logo from 'src/assets/img/logo.png'
import { appConfig } from 'src/app/config/appConfig'

export const AppHeader = () => {
  const { token } = theme.useToken()
  const classes = styles(token)
  const gClasses = gStyles(token)

  const { isAuth, user } = useSelector((state) => state.auth)
  const { drawer } = useSelector((state) => state.config)

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'profile':
        navigate('/profile')
        break

      case 'setting':
        navigate('/setting')
        break

      case 'logout':
        dispatch(authAction.logOut())
        dispatch(studySetAction.resetStudySet())
        dispatch(iotdAction.resetIotd())
        dispatch(mediaAction.reset())
        navigate('/login')
        break

      default:
        break
    }
  }

  const menuProps = {
    items,
    onClick: handleMenuClick,
  }

  const handleAddMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'addItem') {
      dispatch(settingAction.toggleItemModal())
    }

    if (e.key === 'addEx') {
      dispatch(settingAction.toggleExModal())
    }
  }

  const menuAddProps = {
    items: addNewType,
    onClick: handleAddMenuClick,
  }

  return (
    <Layout.Header className={classes.header}>
      <div className={gClasses.containerFluid}>
        <Row
          gutter={[token.size, token.size * 2]}
          justify={'space-between'}
          className={classes.rowHeader}
          style={{ height: 60 }}
        >
          <Col xs={10} md={4}>
            {drawer && (
              <div className={classes.toogleSidebarBtn}>
                <Button
                  type='text'
                  shape='default'
                  className={classes.btnToggle}
                  icon={<MenuOutlined style={{ color: token.colorTextBase }} />}
                  onClick={() => {
                    dispatch(configAction.toggleSidebar())
                  }}
                />

                <div className={classes.logo}>
                  <h1 className={classes.brandName}>
                    <Link to={'/'}>
                      <img src={logo} alt='' />{' '}
                    </Link>
                  </h1>
                </div>

                <Button
                  type={'text'}
                  size={'large'}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  icon={<SearchOutlined style={{ fontSize: 22, color: token.colorPrimary }} />}
                  onClick={() => dispatch(settingAction.toggleSearchFormItem())}
                >
                  {/* <span style={{ fontWeight: '500' }}>Look</span> */}
                </Button>
              </div>
            )}

            {!drawer && (
              <div className={classes.toogleSidebarBtn}>
                <Button
                  type='text'
                  shape='default'
                  className={classes.btnToggle}
                  icon={<MenuOutlined style={{ color: token.colorTextBase }} />}
                  onClick={() => {
                    dispatch(configAction.toggleSidebar())
                  }}
                />
              </div>
            )}
          </Col>

          {isAuth && (
            <Col xs={12} md={16} className={gClasses.fromTablet}>
              <MainMenu direction='horizontal' />
            </Col>
          )}

          <Col xs={14} md={isAuth ? 4 : 18}>
            {isAuth && (
              <div className={classNames(classes.userContainer)}>
                <Dropdown trigger={['click']} menu={menuAddProps} placement='bottomLeft' arrow>
                  <Button
                    className={classNames(classes.btnAddNew)}
                    icon={
                      <PlusOutlined
                        style={{
                          fontSize: token.fontSizeHeading4,
                        }}
                        color={token.colorPrimary}
                      />
                    }
                    onClick={(e) => e.preventDefault()}
                  >
                    Add
                  </Button>
                </Dropdown>

                <Dropdown trigger={['click']} menu={menuProps} placement='bottomLeft' arrow>
                  <Button type='text' onClick={(e) => e.preventDefault()}>
                    <Space>
                      {`Hi,` + ' ' + user.firstName + '!'}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </div>
            )}

            {!isAuth && (
              <div className={classes.userContainer}>
                {/* <Button onClick={() => googleLogin}>Sign in with Google 🚀 </Button> */}

                <Button type='primary' onClick={() => navigate('/login')}>
                  Login
                </Button>

                <Button type='default' onClick={() => navigate('/register')}>
                  Register
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Layout.Header>
  )
}
