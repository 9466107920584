import { theme } from 'antd'
import styles from './style'
import { itemApi } from 'src/app/services/api/item.api'
import { transformItemModelToClient } from 'src/app/helpers/item'
import { SearchOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'src/app/core/hooks/redux'
import { settingAction } from 'src/app/store/reducers/setting.reducer'

interface IPros {
  onSearch?: (tag: string) => void
  label?: string
  tags: string[]
}

export const Tags: React.FC<IPros> = ({ label, tags, onSearch }) => {
  const { token } = theme.useToken()

  const { isShowViewItemModal } = useSelector((state) => state.setting)

  const classes = styles(token)

  const dispatch = useDispatch()

  const getItem = async (origin: string, exact = true) => {
    const { isSuccess, content } = await itemApi.getItems({
      keyword: origin,
      page: 0,
      size: 1,
      exact,
    })
    if (isSuccess && content.data.length > 0) {
      if (!isShowViewItemModal) dispatch(settingAction.showViewItemModal(true))
      dispatch(settingAction.setCurrentItem(transformItemModelToClient(content.data[0])))
    }
  }

  return (
    <div className={classes.tagList}>
      <span className={classes.tagLabel}>{label}:</span>

      {tags.map((tag, key) => {
        return (
          <div key={key} className={classes.tagItem} onClick={() => getItem(tag)}>
            <div className={classes.tagItemContainer}>
              <span>{tag}</span>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  if (onSearch) onSearch(tag)
                }}
              >
                <SearchOutlined />
              </button>
            </div>
          </div>
        )
      })}
    </div>
  )
}
