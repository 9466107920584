import { Col, Row, theme } from 'antd'
import { ECategory, EType, IMeaning, TItem } from 'src/app/models/item.model'
import styles from './style'
import { AudioOutlined } from '@ant-design/icons'
import { useDispatch } from 'src/app/core/hooks/redux'
import { defaultSetting } from 'src/app/config/appConfig'
import { useLocation, useNavigate } from 'react-router-dom'
import { Tags } from '../../components/tags/tags'
import { getTypeOfItem } from 'src/app/helpers/item'
import { settingAction } from 'src/app/store/reducers/setting.reducer'
import { itemAction } from 'src/app/store/reducers/items.reducer'
import { itemAsync } from 'src/app/store/async/item.async'
import classNames from 'classnames'

interface IMeaningProps {
  meaning: IMeaning<string[]>
  catId: ECategory
  type: TItem
}

export const MeaningItemView: React.FC<IMeaningProps> = ({ catId, meaning, type }) => {
  const { token } = theme.useToken()
  const classes = styles(token)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const onSearch = (keyword: string) => {
    dispatch(settingAction.updateViewItemModal(false))
    dispatch(
      itemAction.updatePagination({
        page: 0,
        size: defaultSetting.pagination.size,
      }),
    )
    dispatch(
      itemAction.updateSearchFormValue({
        keyword: keyword,
        cat: ECategory.ALL,
        type: EType.ALL,
        archive: false,
        defect: false,
      }),
    )
    if (location.pathname.includes('library')) {
      dispatch(
        itemAsync.fetchItems({
          keyword: keyword,
          page: 0,
          size: defaultSetting.pagination.size,
          cat: ECategory.ALL,
          type: EType.ALL,
          archive: false,
          defect: false,
        }),
      )
    } else {
      navigate('/library')
    }
  }

  const getPronouns = () => {
    if (
      (catId === ECategory.WORD && meaning.pronunciation.uk) ||
      (catId === ECategory.WORD && meaning.pronunciation.us)
    ) {
      return (
        <div className={classes.pronouns}>
          {meaning.pronunciation.uk && (
            <div className={classes.audio}>
              <span className={classes.accent}>UK</span>
              <AudioOutlined className={classes.audioIcon} />
              {meaning.pronunciation?.uk || ''}
            </div>
          )}

          {meaning.pronunciation.us && (
            <div className={classes.audio}>
              <span className={classes.accent}>US</span>
              <AudioOutlined className={classes.audioIcon} />
              {meaning.pronunciation?.us || ''}
            </div>
          )}
        </div>
      )
    }

    if (catId !== ECategory.WORD && meaning.pronunciation.common) {
      return (
        <div className={classes.pronouns}>
          <div className={classes.audio}>
            <AudioOutlined className={classes.audioIcon} />
            {meaning.pronunciation?.common || ''}
          </div>
        </div>
      )
    }
  }

  return (
    <Row gutter={[token.size, token.size * 2]}>
      <Col span={24}>
        <ul
          className={classNames(
            classes.meaningItem,
            meaning.common || type === 'brief' ? classes.meaningCommon : '',
            meaning.enable ? '' : classes.disableMeaning,
          )}
        >
          {catId === ECategory.WORD && <h4>{getTypeOfItem(meaning.typeId)}</h4>}

          {getPronouns()}

          {meaning.note && (
            <div className={classes.note}>
              {meaning.note
                .replace(/\n/g, '*')
                .replace(/- /g, '')
                .split('*')
                .map((value, key) => (value ? <span key={key}>{`${value}`}</span> : ''))}
            </div>
          )}

          {meaning.definition && (
            <h3
              className={classes.definition}
              dangerouslySetInnerHTML={{
                __html: meaning.definition.replace(/\n/g, '<br />'),
              }}
            />
          )}

          {meaning.translation && (
            <h3
              className={classes.translate}
              dangerouslySetInnerHTML={{
                __html: meaning.translation.replace(/\n/g, '<br />'),
              }}
            />
          )}

          {meaning.collocations && (
            <ul className={classes.listItem}>
              {meaning.collocations
                .replace(/\n/g, '*')
                .replace(/- /g, '')
                .split('*')
                .map((value, key) => (value ? <li key={key}>{value}</li> : ''))}
            </ul>
          )}

          {meaning.grammar && (
            <ul className={classes.listItem}>
              {meaning.grammar
                .replace(/\n/g, '*')
                .replace(/- /g, '')
                .split('*')
                .map((value, key) => (value ? <li key={key}>{value}</li> : ''))}
            </ul>
          )}

          {meaning.synonyms.filter((item) => item).length > 0 && meaning.synonyms.length > 0 && (
            <Tags label={'Synonyms'} tags={meaning.synonyms} onSearch={onSearch} />
          )}

          {meaning.antonyms.filter((item) => item).length > 0 && meaning.antonyms.length > 0 && (
            <Tags label={'Antonyms'} tags={meaning.antonyms} onSearch={onSearch} />
          )}

          {type === 'full' && (
            <>
              {meaning.examples.filter((item) => item).length > 0 &&
                meaning.examples.length > 0 && (
                  <div className={classes.examples}>
                    <h4>Example:</h4>
                    <ul>
                      {meaning.examples.map((example, key) => {
                        return (
                          <li key={key} className={classes.exampleItem}>
                            <ul>
                              <li className={classes.nestedExampleItem}>{example.original}</li>
                              <li className={classes.nestedExampleItem}>{example.translation}</li>
                            </ul>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )}
            </>
          )}
        </ul>
      </Col>
    </Row>
  )
}
