import { AliasToken } from 'antd/es/theme/internal'
import { createUseStyles } from 'react-jss'

export const styles = (token: AliasToken) => {
  return createUseStyles({
    action: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: token.size,
      gap: token.size,
    },
    texture: {
      flex: 1,

      '& textarea': {
        minHeight: '100% !important',
      },
    },
  })()
}
