import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'src/app/core/hooks/redux'
import { Button, Dropdown, MenuProps, Popover, Skeleton, theme } from 'antd'
import styles from './style'
import { EyeOutlined, MoreOutlined, SyncOutlined } from '@ant-design/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { EViewMode } from 'src/app/models/app.model'
import classNames from 'classnames'
import { ECategory, EType, IItem, TItem } from 'src/app/models/item.model'
import { MeaningItemView } from './itemMeaningView'
import { defaultSetting } from 'src/app/config/appConfig'
import gStyles, { appStyleConfig } from 'src/style/appStyle'
import { actionMenuItems } from './actionMenuItem'
import { Reference } from '../references/references'
import moment from 'moment'
import { Tags } from '../../components/tags/tags'
import { getCategory, isDefect } from 'src/app/helpers/item'
import { settingAction } from 'src/app/store/reducers/setting.reducer'
import { itemAction } from 'src/app/store/reducers/items.reducer'
import { itemAsync } from 'src/app/store/async/item.async'
import { Level } from '../../components/level/level'
import { AlertDefectItem } from '../alertDefectItem/alertDefectItem'
import { itemApi } from 'src/app/services/api/item.api'
import { studySetAction } from 'src/app/store/reducers/studySet.reducer'
import { iotdAction } from 'src/app/store/reducers/iotd.reducer'
import { msgWarning } from 'src/app/constants/validation'
import { initSearchFormItem } from 'src/app/services'
import { useToken } from 'src/app/app'
import { ItemType } from 'antd/es/menu/interface'

interface IProps {
  groupAction?: boolean
  active?: boolean
  type: TItem
  data: IItem
  onDelete?: () => void
  onEdit?: () => void
  onView?: () => void
}

export const Item: React.FC<IProps> = ({
  groupAction = true,
  active = false,
  type = 'brief',
  data,
  onDelete,
  onEdit,
  onView,
}) => {
  const { token } = useToken()

  const classes = styles(token)
  const gClasses = gStyles(token)

  const [size, setSize] = useState<number>(8)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const { viewMode } = useSelector((state) => state.config)
  const { formSearchValue } = useSelector((state) => state.items)

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case '0':
        if (onView) onView()
        break

      case '1':
        if (onEdit) onEdit()
        break

      case '2':
        onRelearn(data)
        break

      case '3':
        onReset(data)
        break

      case '4':
        if (onDelete) onDelete()
        break

      default:
        if (onView) onView()
        break
    }
  }

  const getActionMenus = (menus = actionMenuItems) => {
    if (!onView && !onEdit && !onDelete) return []
    return menus
      .map((menu) => {
        if (!onView && menu?.key === 0) return false
        if (!onEdit && menu?.key === 1) return false
        if (!onDelete && menu?.key === 4) return false
        return menu
      })
      .filter((menu) => menu) as ItemType[]
  }

  const menuProps = {
    items: getActionMenus(actionMenuItems),
    onClick: handleMenuClick,
  }

  const onSearch = (keyword: string) => {
    dispatch(settingAction.updateViewItemModal(false))
    dispatch(
      itemAction.updatePagination({
        page: defaultSetting.pagination.page,
        size: defaultSetting.pagination.size,
      }),
    )
    dispatch(
      itemAction.updateSearchFormValue({
        ...initSearchFormItem,
        keyword: keyword,
      }),
    )
    if (location.pathname.includes('library')) {
      dispatch(
        itemAsync.fetchItems({
          ...initSearchFormItem,
          keyword: keyword,
          page: defaultSetting.pagination.page,
          size: defaultSetting.pagination.size,
        }),
      )
    } else {
      navigate('/library')
    }
  }

  const onReset = (data: IItem) => {
    const now = new Date().getTime()
    itemApi.updateItem(data.id as number, {
      level: 0,
      practiceCount: 0,
      created_date: now,
      last_update: now,
    })
    dispatch(
      itemAction.update({
        ...data,
        level: 0,
        practiceCount: 0,
        created_date: now,
        last_update: now,
      }),
    )
    dispatch(
      studySetAction.update({
        ...data,
        level: 0,
        practiceCount: 0,
        created_date: now,
        last_update: now,
      }),
    )
    dispatch(
      iotdAction.update({
        ...data,
        level: 0,
        practiceCount: 0,
        created_date: now,
        last_update: now,
      }),
    )
  }

  const onRelearn = (data: IItem) => {
    itemApi.updateItem(data.id as number, { level: 0 })
    dispatch(itemAction.update({ ...data, level: 0 }))
    dispatch(studySetAction.update({ ...data, level: 0 }))
    dispatch(iotdAction.update({ ...data, level: 0 }))
  }

  useEffect(() => {
    viewMode === EViewMode.LIST ? setSize(24) : setSize(8)
  }, [viewMode])

  return (
    <div
      className={classNames({
        [classes.item]: true,
        active: !active && type === 'full' ? true : false,
      })}
    >
      {!data && <Skeleton />}

      {data && (
        <>
          <div className={classes.contentItem}>
            <div className={classes.contentHead}>
              <div className={classes.title}>
                <h2 className={classes.original}>
                  {type === 'brief' && !isDefect(data) && <span>{data.original}</span>}

                  {type === 'brief' && isDefect(data) && (
                    <Popover title={msgWarning.missingMeaning}>
                      <span className={classes.warnTitle}>{data.original}</span>
                    </Popover>
                  )}

                  {type === 'full' && <span>{data.original}</span>}
                </h2>

                {groupAction && (onView || onEdit || onDelete) && (
                  <Dropdown
                    placement='bottomRight'
                    menu={menuProps}
                    arrow={{ pointAtCenter: true }}
                    trigger={['click']}
                  >
                    <Button
                      size='middle'
                      type='text'
                      icon={<MoreOutlined />}
                      className={classes.btnActions}
                    />
                  </Dropdown>
                )}
              </div>

              {data.archive && (
                <p>
                  <Button className={classes.btnInactive} size='small'>
                    Archived
                  </Button>
                </p>
              )}

              {data.catId && (
                <h5 className={classes.kindOfWord}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: token.size / 2,
                    }}
                  >
                    {type === 'brief' && (
                      <Button
                        className={classes.quickView}
                        type='text'
                        size='small'
                        icon={<EyeOutlined />}
                        onClick={onView}
                      />
                    )}

                    <span>{getCategory(data.catId)}</span>
                  </div>

                  {data.user && (
                    <span>
                      Added by:
                      <span
                        style={{
                          color: token.colorPrimary,
                          fontSize: token.fontSize,
                          marginLeft: token.size / 2,
                        }}
                      >
                        {data.user.username}
                      </span>
                    </span>
                  )}
                </h5>
              )}

              {data.catId && (
                <h5 className={classes.level}>
                  <Level level={data.level as number} />
                </h5>
              )}

              <Reference original={data.original} />

              {data.catId === ECategory.WORD && (
                <>
                  {/* {data.collocations &&
                  data.collocations.filter((item) => item).length > 0 &&
                  data.collocations.length > 0 && (
                    <Tags label={'Collocations'} tags={data.collocations} onSearch={onSearch} />
                  )} */}

                  {data.word_family &&
                    data.word_family.filter((word) => word).length > 0 &&
                    data.word_family.length > 0 && (
                      <div className={classes.word_family}>
                        <Tags label={'Word Family'} tags={data.word_family} onSearch={onSearch} />
                      </div>
                    )}

                  {data.forms &&
                    data.forms.filter((word) => word).length > 0 &&
                    data.forms.length > 0 && (
                      <div className={classes.word_family}>
                        <Tags label={'Forms'} tags={data.forms} onSearch={onSearch} />
                      </div>
                    )}
                </>
              )}

              {data.relation &&
                data.relation.filter((word) => word).length > 0 &&
                data.relation.length > 0 && (
                  <div className={classes.word_family}>
                    <Tags label={'Relation'} tags={data.relation} onSearch={onSearch} />
                  </div>
                )}
            </div>

            {type === 'brief' && data.meanings.length > 0 && (
              <MeaningItemView
                type={type}
                meaning={data.meanings.find((item) => item.common) || data.meanings[0]}
                catId={data.catId as ECategory}
              />
            )}

            {type === 'full' &&
              data.meanings.length > 0 &&
              data.meanings.map((meaning, key) => {
                return (
                  <MeaningItemView
                    key={key}
                    type={type}
                    meaning={meaning}
                    catId={data.catId as ECategory}
                  />
                )
              })}
          </div>

          <div className={classes.date}>
            <span>{moment(data.created_date).format(defaultSetting.dateTimeFormat)}</span>
            <span>{moment(data.last_update).format(defaultSetting.dateTimeFormat)}</span>
          </div>
        </>
      )}
    </div>
  )
}
