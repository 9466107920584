import React, { PropsWithChildren, useState } from 'react'
import { Button, Col, Row, Space, theme } from 'antd'
import styles from './style'
import exstyles from '../item/style'
import { useAutoComplete } from 'src/app/helpers/hooks/autoComplete'
import { Controller, useForm } from 'react-hook-form'
import gStyles from 'src/style/appStyle'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import { IExample } from 'src/app/models/item.model'
import { exampleApi } from 'src/app/services/api/example.api'
import classNames from 'classnames'

interface IProps {
  data?: IExample
  mode?: 'dark' | 'light'
  onSucces?: (data: IExample) => void
}

export const ExampleFormAdd: React.FC<PropsWithChildren & IProps> = ({
  mode = 'dark',
  data,
  onSucces,
}) => {
  const { token } = theme.useToken()
  const classes = styles(token)

  const [loading, setLoading] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<IExample>({
    defaultValues: data || {
      original: '',
      translation: '',
      note: '',
    },
  })

  const onSubmit = async (data: IExample) => {
    setLoading(true)
    try {
      const { content, isSuccess } = await exampleApi.createExample(data)
      setLoading(false)
      reset()
      if (isSuccess) onSucces?.(content)
    } catch (error) {
      console.log(`error: `, error)
    } finally {
      setLoading(false)
      reset()
    }
  }

  return (
    <form
      className={classNames(classes.exampleFormAdd, mode === 'light' ? 'active' : '')}
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: '100%' }}
    >
      <Space direction='vertical' style={{ display: 'flex', width: '100%' }}>
        <Row align={'middle'} gutter={[token.size, token.size]}>
          <Col xs={24}>Origin:</Col>

          <Col xs={24}>
            <Controller
              control={control}
              name={`original`}
              rules={{
                required: {
                  value: true,
                  message: 'Required',
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextArea
                    value={value}
                    placeholder='Origin'
                    className={classes.autoSearchInput}
                    onChange={(text) => onChange(text.target.value)}
                    allowClear={{
                      clearIcon: (
                        <CloseOutlined
                          style={{
                            background: token.colorWhite,
                            padding: token.size / 8,
                            borderRadius: '50%',
                            color: token.colorBgLayout,
                            fontSize: 10,
                          }}
                        />
                      ),
                    }}
                  />
                )
              }}
            />
          </Col>
        </Row>

        <Row align={'middle'} gutter={[token.size, token.size]}>
          <Col xs={24}>Translation:</Col>

          <Col xs={24}>
            <Controller
              control={control}
              name={`translation`}
              rules={{
                required: {
                  value: true,
                  message: 'Required',
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextArea
                    value={value}
                    placeholder='Translation'
                    className={classes.autoSearchInput}
                    onChange={(text) => onChange(text.target.value)}
                    allowClear={{
                      clearIcon: (
                        <CloseOutlined
                          style={{
                            background: token.colorWhite,
                            padding: token.size / 8,
                            borderRadius: '50%',
                            color: token.colorBgLayout,
                            fontSize: 10,
                          }}
                        />
                      ),
                    }}
                  />
                )
              }}
            />
          </Col>
        </Row>

        <Row align={'middle'} gutter={[token.size, token.size]}>
          <Col xs={24}>Note:</Col>

          <Col xs={24}>
            <Controller
              control={control}
              name={`note`}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextArea
                    value={value}
                    placeholder='Note'
                    className={classes.autoSearchInput}
                    onChange={(text) => onChange(text.target.value)}
                    allowClear={{
                      clearIcon: (
                        <CloseOutlined
                          style={{
                            background: token.colorWhite,
                            padding: token.size / 8,
                            borderRadius: '50%',
                            color: token.colorBgLayout,
                            fontSize: 10,
                          }}
                        />
                      ),
                    }}
                  />
                )
              }}
            />
          </Col>
        </Row>

        <Row align={'middle'} gutter={[token.size, token.size]} justify={'end'}>
          <Col>
            <Button
              type='primary'
              htmlType='submit'
              loading={loading}
              disabled={!isValid}
              style={{ minWidth: 120, marginTop: token.size }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Space>
    </form>
  )
}
