import { appConfig } from 'src/app/config/appConfig'
import { Button, theme } from 'antd'
import styles from './style'
import gStyles from 'src/style/appStyle'
import React from 'react'
import { useSelector } from 'src/app/core/hooks/redux'
import { ExportOutlined } from '@ant-design/icons'

interface IPros {
  original: string
}

export const Reference: React.FC<IPros> = ({ original }) => {
  const { token } = theme.useToken()
  const classes = styles(token)
  const gClasses = gStyles(token)

  const { user } = useSelector((state) => state.auth)

  return (
    <div className={classes.references}>
      {appConfig.references.map((reference, key) => {
        if (user.configuration.references.findIndex((item) => item === reference.id) > -1)
          return (
            <Button
              key={key}
              className={gClasses.referenceBtn}
              type='text'
              size='small'
              onClick={() => {
                window.open(reference.url + original, reference.target)
              }}
            >
              {reference.src}
            </Button>
          )
      })}
    </div>
  )
}
