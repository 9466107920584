import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'src/app/core/hooks/redux'
import { Modal } from 'antd'
import { itemAction } from 'src/app/store/reducers/items.reducer'
import { settingAction } from 'src/app/store/reducers/setting.reducer'
import { exampleApi } from 'src/app/services/api/example.api'
import { exampleAction } from 'src/app/store/reducers/example.reducer'

interface IProps {
  onConfirm?: () => void
  onCancel?: () => void
  open: boolean
  title?: string
  content?: string
}

export const DeleteExModal: React.FC = () => {
  const { selectedExample } = useSelector((state) => state.example)
  const { isShowDeleteExModal } = useSelector((state) => state.setting)

  const dispatch = useDispatch()

  const [modal, modalContextHolder] = Modal.useModal()

  useEffect(() => {
    if (isShowDeleteExModal && selectedExample) {
      modal.confirm({
        type: 'warning',
        title: 'Deleting...!',
        content: 'Are you sure you want to delete this example?',
        okText: 'Delete',
        maskClosable: false,
        closable: true,
        onOk: () => {
          exampleApi
            .deleteExample(selectedExample.id as number)
            .then(() => {
              dispatch(exampleAction.filterExamples(selectedExample))
              dispatch(exampleAction.filterRandomExamples(selectedExample))
              dispatch(settingAction.toggleDeleteExModal())
            })
            .catch(() => {
              dispatch(settingAction.toggleDeleteExModal())
            })
        },
        onCancel: () => {
          dispatch(settingAction.toggleDeleteExModal())
        },
      })
    }
  }, [isShowDeleteExModal, selectedExample])

  return <>{modalContextHolder}</>
}
