import React, { useEffect, useState } from 'react'
import { RouteObject, useRoutes } from 'react-router-dom'
import { useSelector } from './core/hooks/redux'
import { NotFound } from './views/pages/notFound/notFound'

export function lazyLoadRoutes(componentName: string) {
  const LazyElement = React.lazy(
    () => import(`./views/pages/${componentName}/${componentName}.tsx`),
  )

  return (
    <React.Suspense
    // fallback={<NotFound />}
    >
      <LazyElement />
    </React.Suspense>
  )
}

export const RouterElement = () => {
  const [router, setRouter] = useState<RouteObject[]>([])

  const { isAuth } = useSelector((state) => state.auth)

  const route = [
    // {
    //   path: '/',
    //   element: lazyLoadRoutes('home'),
    // },
    {
      path: '/login',
      element: lazyLoadRoutes('login'),
    },
    {
      path: '/register',
      element: lazyLoadRoutes('register'),
    },
    {
      path: '/about',
      element: lazyLoadRoutes('about'),
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]

  const authRoute = [
    {
      path: '/',
      element: lazyLoadRoutes('home'),
    },
    {
      path: '/library',
      element: lazyLoadRoutes('library'),
    },
    {
      path: '/example',
      element: lazyLoadRoutes('example'),
    },
    {
      path: '/listening',
      element: lazyLoadRoutes('listening'),
    },
    {
      path: '/statistic',
      element: lazyLoadRoutes('statistic'),
    },
    {
      path: '/about',
      element: lazyLoadRoutes('about'),
    },
    {
      path: '/setting',
      element: lazyLoadRoutes('setting'),
    },
    {
      path: '/profile',
      element: lazyLoadRoutes('profile'),
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]

  useEffect(() => {
    isAuth ? setRouter(authRoute) : setRouter(route)
  }, [isAuth])

  return useRoutes(router)
}
