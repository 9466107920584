import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initAuth, initUser } from 'src/app/services'
import { IUserConfig, ILoginResponse, IUser } from 'src/app/models/user.model'
import { actionAsyncUser } from '../async/user'
import { IHttpResponse } from 'src/app/models/http.model'

export interface IAuthState {
  isAuth: boolean
  user: IUser<number[]>
  authorization: ILoginResponse
}

export const initialState: IAuthState = {
  isAuth: false,
  user: initUser,
  authorization: initAuth,
}

export const authReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logOut() {
      return initialState
    },
    updateUserConfig(state: IAuthState, action: PayloadAction<IUserConfig>) {
      state.user = {
        ...state.user,
        configuration: action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      actionAsyncUser.login.fulfilled,
      (state: IAuthState, action: PayloadAction<IHttpResponse<ILoginResponse>>) => {
        state.isAuth = true
        state.authorization = action.payload.content
      },
    )
    builder.addCase(
      actionAsyncUser.getUserInfo.fulfilled,
      (state: IAuthState, action: PayloadAction<IHttpResponse<IUser>>) => {
        state.isAuth = true
        state.user = action.payload.content
      },
    )
  },
})

export const authAction = authReducer.actions
